import React, { useState } from "react";
import ApplicationLogo from "@BreezeComponents/ApplicationLogo.jsx";
import Dropdown from "@BreezeComponents/Dropdown.jsx";
import NavLink from "@BreezeComponents/NavLink.jsx";
import ResponsiveNavLink from "@BreezeComponents/ResponsiveNavLink.jsx";
import { Link } from "@inertiajs/inertia-react";
import { usePage } from "@inertiajs/inertia-react";
import { main } from "@cssLayouts/Authenticated.module.css";
import clsx from "clsx";
import CustomSnackbar from "@Components/CustomSnackbar.jsx";

const routes = [
  { name: "dashboard", label: "Dashboard" },
  { name: "summary", label: "Summary" },
  { name: "post.order", label: "Post Order" },
  { name: "payments", label: "Payments" }
];

export default function Authenticated({ header, children }) {
  const [showingNavigationDropdown, setShowingNavigationDropdown] =
    useState(false);
  const { auth } = usePage().props;
  const { component } = usePage();
  const [snackBar, setSnackBar] = React.useState({});
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ ...snackBar, open: false });
  };
  const snackBarProp = (prop) => setSnackBar(prop);

  return (
    <div className="min-h-screen">
      <nav className="bg-white border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="shrink-0 flex items-center">
                <Link href="/">
                  <ApplicationLogo className="block h-9 w-auto text-gray-500" />
                </Link>
              </div>
              {routes.map((r, i) => (
                <div
                  className="hidden space-x-8 sm:-my-px sm:ml-10 sm:flex"
                  key={i}
                >
                  <NavLink
                    href={route(r.name)}
                    active={component === r.label.replace(" ", "")}
                  >
                    {r.label}
                  </NavLink>
                </div>
              ))}
            </div>

            <div className="hidden sm:flex sm:items-center sm:ml-6">
              <div className="ml-3 relative">
                <Dropdown>
                  <Dropdown.Trigger>
                    <span className="inline-flex rounded-md">
                      <button
                        type="button"
                        className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                      >
                        {auth.user?.first_name} {auth.user?.last_name}
                        <svg
                          className="ml-2 -mr-0.5 h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </span>
                  </Dropdown.Trigger>

                  <Dropdown.Content>
                    <Dropdown.Link
                      href={route("logout")}
                      method="post"
                      as="button"
                    >
                      Log Out
                    </Dropdown.Link>
                  </Dropdown.Content>
                </Dropdown>
              </div>
            </div>

            <div className="-mr-2 flex items-center sm:hidden">
              <button
                onClick={() =>
                  setShowingNavigationDropdown(
                    (previousState) => !previousState
                  )
                }
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    className={
                      !showingNavigationDropdown ? "inline-flex" : "hidden"
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                  <path
                    className={
                      showingNavigationDropdown ? "inline-flex" : "hidden"
                    }
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            (showingNavigationDropdown ? "block" : "hidden") + " sm:hidden"
          }
        >
          {routes.map((r, i) => (
            <div className="pt-2 pb-3 space-y-1" key={i}>
              <ResponsiveNavLink
                href={route(r.name)}
                active={route().current(r.name)}
              >
                {r.label}
              </ResponsiveNavLink>
            </div>
          ))}

          <div className="pt-4 pb-1 border-t border-gray-200">
            <div className="px-4">
              <div className="font-medium text-base text-gray-800">
                {auth.user?.first_name} {auth.user?.last_name}
              </div>
              <div className="font-medium text-sm text-gray-500">
                {auth.user?.email}
              </div>
            </div>

            <div className="mt-3 space-y-1">
              <ResponsiveNavLink
                method="post"
                href={route("logout")}
                as="button"
              >
                Log Out
              </ResponsiveNavLink>
            </div>
          </div>
        </div>
      </nav>

      {header && (
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            {header}
          </div>
        </header>
      )}

      <main className={clsx("py-12", main)}>
        {React.cloneElement(children, {
          snackBar: snackBarProp,
        })}
      </main>
      <CustomSnackbar
        open={snackBar.open}
        message={snackBar.message}
        status={snackBar.status}
        handleClose={handleClose}
      />
    </div>
  );
}
