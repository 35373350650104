import React from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/inertia-react";
import { InertiaProgress } from "@inertiajs/progress";
import Authenticated from "@Layouts/Authenticated.jsx";
import Guest from "@Layouts/Guest.jsx";
import ErrorBoundary from "@Components/ErrorBoundary.jsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "../css/app.css"; //import the app css
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(import.meta.env.VITE_MATERIAL_KEY);

const container = document.getElementById("app");
const root = createRoot(container);
const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
  },
});
const appName =
  window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";
const guestLayouts = [
  "ConfirmPassword",
  "ForgotPassword",
  "Login",
  "Register",
  "ResetPassword",
  "VerifyEmail",
  "Welcome",
];
createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: async (name) => {
    const pages = import.meta.glob("./Pages/**/*.jsx");
    const page = (await pages[`./Pages/${name}.jsx`]()).default;
    const splitName = name.split("/")[1];
    const Layout = guestLayouts.includes(splitName) ? Guest : Authenticated;
    page.layout = (page) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <React.StrictMode>
          <ErrorBoundary>
            <ThemeProvider theme={theme}>
              <Layout>{page}</Layout>
            </ThemeProvider>
          </ErrorBoundary>
        </React.StrictMode>
      </LocalizationProvider>
    );
    return page;
  },
  setup({ App, props }) {
    root.render(React.createElement(App, props));
  },
});

InertiaProgress.init({
  // The delay after which the progress bar will
  // appear during navigation, in milliseconds.
  //delay: 250,

  // The color of the progress bar.
  color: "#29d",

  // Whether to include the default NProgress styles.
  includeCSS: true,

  // Whether the NProgress spinner will be shown.
  showSpinner: false,
});
